import mqtt from 'mqtt'
import { env } from 'store/server-context/static'
import { shortId } from 'utils/short-id'

export const connectMqtt = (clientId: string): mqtt.MqttClient => {
  const options: mqtt.IClientOptions = {
    clientId,
    keepalive: 60,
    protocolId: 'MQTT',
    protocolVersion: 4,
    clean: false,
    reconnectPeriod: 5000,
    connectTimeout: 30 * 1000,
  }

  const client = mqtt.connect(env.HOST_MQTT, options)

  return client
}

export const generateClientId = (userId: string) =>
  `web_${shortId(5)}/${userId}`
