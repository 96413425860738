'use client'

import { userNotification } from 'features/account/utils/user-notification'
import React from 'react'
import { session } from './server-context/static'

const listeners = new Set<(data: unknown) => void>()

export const subscribeUserNotification = (
  listener: (data: unknown) => void,
) => {
  listeners.add(listener)
  return () => {
    listeners.delete(listener)
  }
}

export const UserNotification = () => {
  const userId = session.userId

  React.useEffect(() => {
    return userNotification(
      { userId },
      {
        onMessage: data => {
          listeners.forEach(listener => listener(data))
        },
      },
    )
  }, [userId])

  return null
}
