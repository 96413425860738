import { createSelectors } from '@paladise/utils/zustand-selector'
import { getAccountCredits } from 'api/purchase'
import { clientFetch } from 'lib/fetch/client'
import { createWithEqualityFn as create } from 'zustand/traditional'
import { session } from './server-context/static'
import { subscribeUserNotification } from './user-notification'

export type CreditState = {
  initialized: boolean
  credit: number
}

export type CreditActions = {
  syncCredit: () => void
}

export type CreditStore = CreditState & CreditActions

const useCreditStoreBase = create<CreditStore>((set, get) => ({
  credit: 0,
  initialized: false,
  syncCredit: () => {
    let timer: NodeJS.Timeout
    let canceled = false
    let isNewAccount = session.guest && session.isShowAgeConfirmation
    let times = isNewAccount ? 0 : 10

    async function fetchQuota() {
      const { data } = await getAccountCredits(clientFetch).catch(() => ({
        data: null,
      }))
      if (canceled) {
        return
      }
      set({ initialized: true })
      if (data) {
        set({ credit: data.credit })
        if (data.credit !== 0) {
          times = 10
        }
      }

      timer = setTimeout(fetchQuota, times < 10 ? 500 : 10000)
      times++
    }

    const unsub = subscribeUserNotification(data => {
      const _data = data as {
        action_type: 'credit_update'
        payload: {
          error_code: string
          error_msg: string
          remain_credit: number
        }
      }

      if (_data.action_type !== 'credit_update') {
        return
      }

      set({ credit: _data.payload.remain_credit })
    })

    fetchQuota()

    return () => {
      clearTimeout(timer)
      unsub()
      canceled = true
    }
  },
  updateCredit: (credit: number) => set({ credit }),
}))

export const useCreditStore = createSelectors(useCreditStoreBase)
