import { safeJsonParse } from '@paladise/utils/safe-json-parse'
import { connectMqtt } from 'lib/mqtt'

export const userNotification = (
  config: { userId: string },
  {
    onMessage,
  }: {
    onMessage: (data: unknown | null) => void
  },
) => {
  const { userId } = config

  const client = connectMqtt(userId)

  client.subscribe(`app/notify/${userId}`)

  client.on('message', (_, message) => {
    const data = safeJsonParse(message.toString())
    onMessage(data)
  })

  return () => {
    client.end()
  }
}
